//imports
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Inconsolata:wght@300&display=swap');

//variable
$terminalTextColor: #0f0;
$terminalFontFamily: "Inconsolata",monospace;
$primaryFontFamily: 'Anton', sans-serif;

html body{
    background-color: black;
    margin: 0px!important;
    padding: 0px!important;
}

//Keyframes
@keyframes fadeIn {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
} 

@keyframes fadeRight {
    0.0%{
        left: 15px;
        opacity: 0;
        transform: translate(-80px);
    }
    100%{
        opacity: 1;
        transform: translate(0px);
    }
}

@keyframes fadeLeft {
    0.0%{
        left: 15px;
        opacity: 0;
        transform: translate(80px);
    }
    100%{
        opacity: 1;
        transform: translate(0px);
    }
} 

@keyframes fadeUp {
    0.0%{
        left: 15px;
        opacity: 0;
        transform: translate(0px, 80px);
    }
    100%{
        opacity: 1;
        transform: translate(0px, 0px);
    }
} 

@keyframes fadeDown {
    0.0%{
        left: 15px;
        opacity: 0;
        transform: translate(0px, -80px);
    }
    100%{
        opacity: 1;
        transform: translate(0px, 0px);
    }
} 

@keyframes darkCoffee {
    0% { background-color: #3F2D27; box-shadow: 0 0 5px #3F2D27; }
    50% { background-color: #3F2D27; box-shadow: 0 0 20px #3F2D27; }
    100% { background-color: #3F2D27; box-shadow: 0 0 5px #3F2D27; }
}

@keyframes greyCoffee {
    0% { background-color: #4D4141; box-shadow: 0 0 5px #4D4141; }
    50% { background-color: #4D4141; box-shadow: 0 0 20px #4D4141; }
    100% { background-color: #4D4141; box-shadow: 0 0 5px #4D4141; }
}

@keyframes lightCoffee {
    0% { background-color: #473A2E; box-shadow: 0 0 5px #473A2E; }
    50% { background-color: #473A2E; box-shadow: 0 0 20px #473A2E; }
    100% { background-color: #473A2E; box-shadow: 0 0 5px #473A2E; }
}

@keyframes white {
    0% { background-color: #fff; box-shadow: 0 0 5px #fff; }
    50% { background-color: #fff; box-shadow: 0 0 20px #fff; }
    100% { background-color: #fff; box-shadow: 0 0 5px #fff; }
}

@keyframes bounce {
    0.0%{
    }
    100%{
        transform: translate(0px, 0px);
    }
    50.4%{
        transform: translate(0px, 20px);
    }
} 

//Components

//Modal
// .CusModal{
//     height: 100vh;
//     width: 100vw;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

#menuContainer{
    position: fixed;
    bottom: 0;
    right: 0;
}

.menuItem{
    border-radius: 100%;
    height: fit-content;
    width: fit-content;
    padding: 10px;
    transition: 0.5s ease-in-out;
    margin: 10px;
    &:hover{
        cursor: pointer;
    }
    &:nth-child(1){
        animation: fadeRight 1s ease 0s 1 normal forwards, white 1300ms infinite;
    }
    &:nth-child(2){
        animation: fadeUp 1s ease 0s 1 normal forwards, lightCoffee 1300ms infinite;
    }
    &:nth-child(3){
        animation: fadeLeft 1s ease 0s 1 normal forwards, greyCoffee 1300ms infinite;
    }
    &:nth-child(4){
        animation: fadeDown 1s ease 0s 1 normal forwards, darkCoffee 1300ms infinite;
    }
    
}

.menuIcon{
    font-size: 1.8rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    transition: 0.2s ease-in-out;
    color: #fff;
}

.CusModal-content{
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 9999;
    border-radius: 10px;
}

#modalOverlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
}

//Terminal
#terminal{
    height: 300px;
    width: 600px;
    background-color: #1f1a1b;
    border-radius: 5px;
    border: 1px solid white;
    animation: fadeIn 1s ease 0s 1 normal forwards;
}

#terminal_header{
    width: 100%;
    height: auto;
    background-color: #524e46;
    display: flex;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.terminal_header_buttons{
    height:12px;
    width: 12px;
    border-radius: 100%;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.terminal_body{
    color: $terminalTextColor;
    font-family: $terminalFontFamily;
    margin:5px;
    flex-direction: column;
}

#cursorAnim{
    font-weight: 100;
    font-size: 20px;
    animation: typingCursor 1s ease 0s infinite normal none;
}

@keyframes typingCursor {
    0.0%{
    }
    50.3%{
        opacity: 0;
    }
}

#terminal_button_container{
    display: flex;
    flex-direction: row;
}

.terminal_button{
    margin-left:5px;
    flex-direction: row;
    background-color: transparent!important;
    border-color: $terminalTextColor!important;
    color: $terminalTextColor!important;
    &:hover{
        background-color: $terminalTextColor!important;
        color: black!important;
    }
}

//Helper Comps
#social{
    height:fit-content;
    width:inherit;
}

.socialIcons{
    color: #AB6E50;
    margin: 5px;
    font-size: 30px;
    transition: 0.2s ease-in-out;
    &:hover{
        cursor:pointer;
        transform:scale(1.2)
    }
}

//Pages

//Landing page
#landing{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

#landing_terminal{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
}

.logo{
    height:30vh;
    border:1px solid green;
}

//Projects and Skills
#projects_and_skills{
    min-height: 100vh;
    height: fit-content;
    width: 100vw;
    background-color: black;
    overflow-x: hidden;
    font-family: $terminalFontFamily;
    color: $terminalTextColor;
}

#skillsHolder{
    padding: 20px;
    text-align: center;
    justify-content: center;
    // min-height: 100vh;
    // height: fit-content;
    width: 100%;
    background-color: #1f1a1b;
        p{
            color: $terminalTextColor;
            font-family: $terminalFontFamily;
            border: 1px solid $terminalTextColor;
            width: fit-content;
            padding: 5px;
            display: inline-block;
            margin: 1px;
        }
}

#projectsHolder{
    // min-height: 100vh;
    // height: fit-content;
    width: 100%;
    background-color: #3a2e2e;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
}

.Cols{
    padding: 0px!important;
    display: flex;
}

.projects_terminal{
    margin:5px;
    transition: 0.1s ease-in-out;
    &:hover{
        transform: scale(1.02);
    }
}

//Admin
#admin_upload_project{
    color: $terminalTextColor;
    font-family: $terminalFontFamily;
    padding: 10px;
}

.admin_upload{
    color: $terminalTextColor;
    font-family: $terminalFontFamily;
    border: 1px solid $terminalTextColor;
    width: fit-content;
}

//Contact
#contact{
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #1C0F0B;
    text-align: center;
    font-family: $primaryFontFamily;
    flex-direction: column;
        p, a{
            font-family: 'Courier New', Courier, monospace;
            color: #AB6E50;
            text-decoration: none;
        }
        h2{
            font-family: $primaryFontFamily;
            color: #AB6E50;
        }
        h4{
            color:white;
        }
        a:hover{
            color: #bd7b59;
            text-decoration: underline;
        }
        input{
            width: 80vw;
            height: 60px;
            border-radius: 20px;
            padding: 10px;
            margin:5px;
            transition: 0.1s ease-in-out;
            &::placeholder-shown{
                font-family: $primaryFontFamily;
            }
            &:focus{
                transform: scale(1.01);
            }
        }
        textarea{
            width: 80vw;
            height: 30vh;
            border-radius: 20px;
            padding: 10px;
            margin:5px;
            transition: 0.1s ease-in-out;
            &::placeholder-shown{
                font-family: $primaryFontFamily;
            }
            &:focus{
                transform: scale(1.01);
            }
        }
}

#formHolder{
    padding: 20px;
}

#contactRow{
    width:50%;
    margin:auto
}

#contactWelcome{
    font-size:10vh;
    color:#AB6E50;
    animation: fadeIn 1s ease 0s 1 normal forwards;
}

//About
#aboutContainer{
    min-height: 100vh;
    height: fit-content;
    width: 100vw;
    background-color: #2b2118;
    text-align: center;
    font-family: $terminalFontFamily;
    padding:20px;
    color:white;
        h3{
            font-family: $primaryFontFamily;
        }
        a{
            text-decoration: none;
            color: #AB6E50;
        }
        a:hover{
            text-decoration: underline;
        }
    }

#abtLogo{
    transition:0.5s ease-in-out;
    animation: bounce 3s ease 0s infinite normal none;
}

#bd{
    background: linear-gradient(to right, green, red);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.programImg{
    height: 80px;
    margin:10px;
    transition: 0.2s ease-in-out;
    filter:grayscale(1);
        &:hover{
            filter:grayscale(0);
        }
}
